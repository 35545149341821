<template>
    <div class="action-schedule-complete">
        <div class="title f-bold" v-html="`장소가 확정 되었어요.`" />
        <ScheduleProfile
            :user="getUser(content)"
            :date="content.date_confirmed"
            :place="content.meet_place"
            :restaurant="getRestaurant"
            :food="foodPrefer"
            class="meeting-info"
            :meetType="meetType"
        />
    </div>
</template>

<script>
export default {
    name: 'ActionScheduleReservationComplete',
    props: ['message'],
    components: {},
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        getUser() {
            return person => {
                return {
                    photo: person.urls[0],
                    name: person.name,
                    birthday: person.birthday,
                }
            }
        },
        foodPrefer() {
            if (this.content.foods_unpreferred_status === 0) {
                return '다 잘먹어요'
            } else {
                return this.content.foods_unpreferred
            }
        },
        getRestaurant() {
            return {
                restaurant_url: this.content.restaurant_url,
                restaurant_name: this.content.restaurant_name,
            }
        },
        meetType() {
            const content = [
                {
                    id: 1,
                    name: 'meal',
                    title: '식사',
                    selected: false,
                },
                {
                    id: 2,
                    name: 'coffee',
                    title: '커피',
                    selected: false,
                },
                {
                    id: 3,
                    name: 'both',
                    title: '식사 또는 커피',
                    selected: false,
                },
            ]
            return content.find(c => c.name === this.content.meet_type_confirmed).title
        },
    },
    methods: {},
}
</script>

<style scoped lang="scss">
.action-schedule-complete {
    .title {
        padding: 0;
    }
    width: 100%;
    padding: 16px;
    .meeting-info {
        width: 100%;
    }
}
</style>
